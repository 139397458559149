import React from "react"
import { Translations } from "../utils/translations"
import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"
import { Link } from "gatsby"

const SsoIssue = () => (
  <div className="pt-60 pr-70 pb-60 pl-70 color-secondary-1">
    <h1>{Translations.ssoIssue.title}</h1>
    <Text globalModifiers="text-size-medium font-secondary-bold mt-10">
      {Translations.ssoIssue.description}
      <Link className="link text-underline color-secondary-1" to="/help">
        Help Page.
      </Link>
    </Text>
  </div>
)

export default SsoIssue
